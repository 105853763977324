// Label
.form-label {
	@include font($label-font-size, $label-line-height, $font-weight-normal);
	display: block;
}

// Group
.form-group {
	&.is-required {
		.form-label:after {
			color: $danger;
			content: " *";
		}
	}
}


/**
 * Number input
 */
.form-quantity-control {
	$btn-width: 32px;
	position: relative;

	.form-control {
		padding-left: $btn-width;
		padding-right: $btn-width;
		text-align: center;
		min-width: (2*$btn-width + 50);
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	// +/- buttons
	.btn {
		position: absolute;
		text-align: center;
		@include font(px2rem(16), 1, $font-weight-bold);
		top: 50%;
		transform: translateY(-50%);
		width: $btn-width;

		&.btn-minus {
			left: 0
		}

		&.btn-plus {
			right: 0;
		}
	}

	// Small
	&.form-quantity-control-sm {
		$btn-width: 20px;

		.form-control {
			padding-left: $btn-width;
			padding-right: $btn-width;
			min-width: (2*$btn-width + 50);
		}

		.btn {
			@include font(px2rem(14), 1);
			width: $btn-width;
			height: $input-height-sm;
		}
	}
}

/**
 * Custom control
 */
.custom-control {
	// Radio
	&.custom-radio {
		$_radioTop: ($custom-control-indicator-size / 2) - ($font-size-sm * 1.2 / 2);
		.custom-control-label {
			padding-top: $_radioTop;
			min-height: $custom-control-indicator-size;
			@include font($font-size-sm, 1.2);
			cursor: pointer;
		}
		.custom-control-input:checked ~ .custom-control-label {
			font-weight: $font-weight-bold;
			&:after {
				background: none;
				border: $custom-control-indicator-checked-border-width solid $custom-control-indicator-checked-border-color;
				border-radius: 100%;
			}
		}

		// Image
		&.has-image {
			display: flex;
			flex-direction: column-reverse;
			justify-content: flex-end;
			border: 2px solid $gray-200;
			border-radius: $border-radius;
			padding-top: spacer(3);
			padding-bottom: spacer(3);
			@include transitionCustom;
			cursor: pointer;
			height: 100%;
			.image {
				text-align: center;
				margin-bottom: spacer(2);
				position: relative;
				margin-left: 0 - ($custom-control-gutter + $custom-control-indicator-size);
			}
			.custom-control-label {
				margin: 0 spacer(3);
			}

			#{$no-touch} &:hover {
				border-color: $primary;
				box-shadow: $box-shadow-lg;
			}

			// Checked
			&.is-checked {
				border-color: $primary;
			}
		}

		// Border
		&.has-border {
			display: flex;
			flex-direction: column-reverse;
			justify-content: center;
			border: 2px solid $gray-200;
			border-radius: $border-radius;
			padding-top: px2rem(20);
			padding-bottom: px2rem(20);
			@include transitionCustom;
			cursor: pointer;
			height: 100%;
			min-height: 72px;
			.custom-control-label {
				margin: 0 px2rem(22);
				font-size: $font-size-base;
				i {
					@include font(px2rem(22), $custom-control-indicator-size);
					margin-top: -($_radioTop);
				}
			}

			#{$no-touch} &:hover {
				border-color: $primary;
				box-shadow: $box-shadow-lg;
				.custom-control-label i {
					color: $primary;
				}
			}

			// Checked
			&.is-checked {
				border-color: $primary;
				.custom-control-label i {
					color: $primary;
				}
			}
		}
	}
	// Checkbox
	&.custom-checkbox {
		.custom-control-label {
			padding-top: ($custom-control-indicator-size / 2) - ($font-size-sm * 1.2 / 2);
			@include font($font-size-sm, 1.2);
		}
		.custom-control-input:checked ~ .custom-control-label {
			font-weight: $font-weight-bold;
			&:before {
				background: $primary;
			}
			&:after {
				color: $white;
				content: $icon-check;
				@include fontFamily("ico");
				background: none;
				@include font(px2rem(8), $custom-control-indicator-size);
				width: $custom-control-indicator-size;
				text-align: center;
			}
		}
	}
}
