/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}


@function px2rem($size) {
	@return ((strip-unit($size) / 16) * 1rem);
}

@function fontRound($size, $multiplier: 16)
{
	@return px2rem(round(strip-unit($size) * $multiplier));
}

@function spacer($size) {
	@return map_get($spacers, $size);
}