.btn {
	font-weight: $btn-font-weight;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	letter-spacing: .04em;

	&.btn-min {
		min-width: calc(min(220px, 100%));
		justify-content: space-between;
	}

	&.btn-block {
		display: flex;
	}

	span + i, i + span {
		margin-left: px2rem(16);
	}

	&.btn-sm {
		span + i, i + span {
			margin-left: px2rem(8);
		}
	}

	i[data-size="sm"] {
		font-size: px2rem(9);
	}

	i[data-size="lg"] {
		font-size: px2rem(18);
	}

	&.btn-icon {
		padding-left: 0 !important;
		padding-right: 0 !important;
		width: $btn-min-height;
		border-radius: 100% !important;
		&.btn-lg {
			width: $btn-min-height-lg;
		}

		&.btn-sm {
			width: $btn-min-height-sm;
		}

		i {
			line-height: inherit;
		}
	}
}

.input-group .btn {
	border-radius: $input-border-radius;
}

.btn-outline-primary {
	background-color: $primary-alt;
	color: $primary;
	border-color: $primary-alt;
	&:hover {
		background-color: darken($primary-alt, 4%);
		border-color: darken($primary-alt, 4%);
		color: $primary;
	}
}

.btn-light {
	i {
		color: $warning;
	}
}

.btn-outline-light {
	color: $gray-600;
	border-color: $gray-600;
	i {
		color: $primary;
	}
}

.btn-white {
	color: $primary;
}

.btn-link {
	color: $gray-600;
	@include underline(0,0);
	padding: 0 !important;
	&:hover {
		color: $primary;
	}
}

.btn-editor {
	$size: 30px;
	background-color: rgba($black, .8);
	border-width: 0;
	height: $size;
	min-width: $size;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	@include font(px2rem(12), 1);
	padding: 0;
	border-radius: 4px;
	margin: 0 !important;
	box-shadow: none !important;
	&.is-danger {
		background-color: $primary;
	}


	&:hover {
		background-color: $black;
		color: $primary-alt;
	}
	&:focus {
		background-color: $black;
	}
}


.btn-editor-image {
	$size: 36px;
	background-color: rgba($black, .8);
	border-width: 0;
	height: $size;
	min-width: $size;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	@include font(px2rem(15), 1);
	padding: 0;
	border-radius: 50%;
	margin: 0 !important;
	box-shadow: none !important;

	&:hover {
		background-color: $primary-alt;
		color: $primary;
	}
	&:focus {
		background-color: $primary-alt;
		color: $primary;
	}
}
