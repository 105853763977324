/**
 * Headline
 */
.section__headline {
	border-top: 1px solid lighten($dark, 4%);
	background-color: $dark;
	background-repeat: no-repeat;
	background-position: center top;
	background-image: url("#{$dir}/image/headline-bg.jpg");
	background-size: cover;
	position: relative;
	color: $white;

	.breadcrumb li a:hover {
		color: $white;
	}

	&.is-custom {
		background-position: center bottom;
	}

	@include media-breakpoint-down(md) {
		&:not(.is-custom) {
			background-size: auto;
			background-position: center bottom;
		}
	}
	@include media-breakpoint-down(sm) {
		&:not(.is-custom) {
			background-size: 1200px auto;
		}
	}
}

/**
 * Pictures example
 */
.section__pictures-example {
	$_imageIndent: px2rem(20);

	.image-container {
		text-align: center;
		.bubbles {
			text-align: left;
			.bubble {
				display: flex;
				align-items: center;
				> p {
					margin-left: $_imageIndent;
				}
			}
		}
	}

	/** Responsive **/
	@include media-breakpoint-up(md) {
		.image-container {
			position: relative;
			display: inline-block;
			.bubble {
				white-space: nowrap;
				position: absolute;

				&:nth-child(1), &:nth-child(3) {
					flex-direction: row-reverse;
					> p {
						margin-left: auto;
						margin-right: $_imageIndent;
						text-align: right;
					}
				}

				&:nth-child(1) {
					top: 18%;
					right: 68%;
				}

				&:nth-child(2) {
					bottom: 14%;
					left: 70%;
				}

				&:nth-child(3) {
					top: 80%;
					right: 74%;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.image-container {
			.bubbles {
				display: inline-flex;
				flex-direction: column;
				.bubble + .bubble {
					margin-top: $_imageIndent;
				}
			}
		}
	}
}

/**
 * Editor container
 */
.section__editor {
	position: relative;
}

/**
 * Editor header
 */
.section__editor--header {
	background-color: $gray-100;
	padding: spacer(3) 0;
}

/**
 * Editor footer
 */
.section__editor--footer {
	background-color: $primary;
	color: $white;
	padding: px2rem(12) 0;
}
