.bg-xlight {
	background-color: $gray-100;
}

/**
 * Hr
 */
.hr-image {
	color: $primary;
	@include font(px2rem(11), 1);
}

/**
 * List
 */
.check-list {
	$top: ($line-height-base * $font-size-base) / 2;

	li {
		position: relative;
		padding-left: px2rem(20);
		&:before {
			position: absolute;
			left: 0;
			top: $top;
			transform: translateY(-50%);
			display: block;
			content: $icon-check;
			color: $primary;
			@include fontFamily("ico");
			font-size: px2rem(10);
		}
	}
	li + li {
		margin-top: spacer(4);
	}
}


/**
 * Primary link
 */
.link-primary {
	@include transitionCustom;
	font-weight: $font-weight-bold;
}

/**
 * Services list
 */
.services-list {
	@include media-breakpoint-up(sm) {
		columns: 2;
		column-gap: px2rem(20);
	}
}/**
 * Image zoom
 */
.image-zoom {
	position: relative;
	display: inline-block;

	&:before, &:after {
		@include transitionCustom;
		position: absolute;
		display: block;
		pointer-events: none;
		opacity: 0;
	}

	// Bg
	&:before {
		background-color: rgba($dark, .8);
		content: "";
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		border-radius: inherit;
	}

	// Icon
	&:after {
		content: $icon-zoom;
		@include fontFamily("ico");
		color: $white;
		@include font(px2rem(18), 1);
		display: block;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) scale(0);
	}

	// Image
	img {
		border-radius: inherit;
	}

	#{$no-touch} &:hover {
		&:before, &:after {
			opacity: 1;
		}

		&:after {
			transform: translate(-50%, -50%) scale(1);
		}
	}
}

/**
 * Max content width on XS
 */
.max-width-xs {
	@include media-breakpoint-down(xs) {
		@include maxWidth(360px);
	}
}

/**
 * Default link style
 */
.link-default {
	color: currentColor;
	@include underline(1, 0);
	@include transitionCustom(color);

	#{$no-touch} &:hover {
		color: $primary;
	}
}

/**
 * Navigation arrow (slider)
 */
.nav-arrow {
	color: $body-color;
	@include font(px2rem(20), 1);
	display: block;
	background: transparent;
	border-width: 0;
	padding: 0;
	margin: 0;
	outline: 0 !important;
	box-shadow: none;
	@include underline(0,0);

	&:hover {
		color: $primary;
	}
}

/**
 * Link underline
 */
.link-underline {
	font-weight: $font-weight-bold;
	display: inline-flex;
	align-items: center;
	@include underline(0,0);
	padding-bottom: 3px;
	position: relative;

	span + i, i + span {
		margin-left: px2rem(4);
	}

	&:before {
		pointer-events: none;
		background-color: currentColor;
		height: 2px;
		left: 0;
		right: 0;
		top: 100%;
		content: "";
		display: block;
		@include transitionCustom;
		position: absolute;
	}
	&:hover:before {
		left: 10%;
		right: 10%;
	}

	i[data-size="sm"] {
		font-size: px2rem(9);
	}
}

/**
 * Online status
 */
.online-status {
	text-align: center;
	display: inline-flex;
	align-items: center;
	font-weight: $font-weight-bold;

	.status {
		margin-right: px2rem(6);
		background-color: currentColor;
		border-radius: 50%;
		width: 6px;
		height: 6px;
		display: inline-block;
	}
}

/**
 * Success message
 */
.success-message {
	border: 2px solid $border-color;
	padding: spacer(4) spacer(4);
	@include maxWidth(500px);
	border-radius: $border-radius;
	text-align: center;
}

/**
 * Order container
 */
.order-container {
	.component__order-card + .component__order-card {
		margin-top: px2rem(14);
	}

	@include media-breakpoint-up(sm) {
		background: $gray-100;
		border-radius: $border-radius;
		padding: px2rem(14);
	}
	@include media-breakpoint-down(xs) {
		.component__order-card {
			box-shadow: 0 1px 8px 0 rgba(0,0,0,0.12);
		}
	}
}

// Icon link
.link-icon {
	svg {
		* {
			@include transitionCustom;
		}
	}

	&:hover, &.active {
		svg {
			.stroke {
				fill: $primary;
			}

			.bg {
				fill: $primary-alt;
			}

			.other {
				fill: $gray-400;
			}
		}
	}
}

/*
// Default link underline
.link-underline {
	color: $secondary;
	@include underline(1, 0);
	@include transitionCustom(color);

	#{$no-touch} &:hover {
		color: $primary;
	}
}

// Default link style
.link-default {
	color: $secondary;
	@include underline(0, 1);
	@include transitionCustom(color);

	#{$no-touch} &:hover {
		color: $primary;
	}
}

// Default link
.link-color {
	color: $secondary;
	@include underline(0, 0);
	@include transitionCustom(color);

	#{$no-touch} &:hover {
		color: $primary;
	}
}

// Link with image
.link-w-image {
	color: $secondary;
	@include underline(0, 1);
	@include transitionCustom(color);

	#{$no-touch} &:hover {
		color: $primary;

		img {
			box-shadow: $box-shadow;
		}
	}

	img {
		@include transitionCustom(box-shadow);
		margin-bottom: spacer(4);
	}
}

// Product tags
.product-tags {
	@include font($font-size-sm, $line-height-sm, $font-weight-bold);

	a:not(:last-child) {
		margin-right: spacer(2);
	}
}

// Product labels
.product-labels {
	line-height: 1;
	pointer-events: none;

	li + li {
		margin-top: spacer(1);
	}
}

// Image hover (plus)
.image-hover {
	position: relative;
	overflow: hidden;

	&:before {
		pointer-events: none;
		opacity: 0;
		border-radius: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		transform: scale(.1);
		content: "";
		background-color: rgba($black, .7);
		display: block;
		@include transitionCustom;
		z-index: 10;
	}

	&:after {
		pointer-events: none;
		@include fontFamily("ico");
		@include transitionCustom;
		content: $icon-plus;
		color: $white;
		opacity: 0;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: block;
		z-index: 11;
		@include font(px2rem(20), 1);
	}

	&:hover {
		&:before {
			opacity: 1;
			border-radius: 0;
			transform: none;
		}

		&:after {
			opacity: 1;
		}
	}
}

*/
