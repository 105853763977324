body.is-editor {
	.body-container {
		overflow: initial;
	}
}

.body-container {
	overflow-x: hidden;
}


/**
 * Header
 */
.header-container {
	z-index: 500;
	background: $dark;
	position: relative;

	.header {
		height: $header-height;
	}

	// Basket
	.btn-basket-container {
		.btn {
			position: relative;
			background-color: $gray-800;
			color: $primary;
			i {
				font-size: px2rem(20);
			}
			.n {
				position: absolute;
				right: 0;
				top: 0;
				border-radius: 100%;
				background-color: $success;
				color: $white;
				text-align: center;
				min-width: 20px;
				height: 20px;
				@include font(px2rem(12), px2rem(20), $font-weight-bold);
				padding: 0 px2rem(2) 0 px2rem(4);
				transform: translate(25%, -25%);
				margin: 0;
			}

			&:hover, &:active, &:focus {
				background-color: $white;
			}
		}
	}

	// ** Responsive **/
	@include media-breakpoint-down(xs) {
		.header {
			height: $header-height-xs;
			.logo img {
				width: 130px;
			}
		}
	}
}

// **
// Main menu
// **
.main-menu {
	display: flex;
	// First level
	> li {
		position: relative;
		+ li {
			margin-left: spacer(5);
		}

		> a {
			@include underline(0, 0);
			@include font(px2rem(16), 1.4, $font-weight-medium);
			@include transitionCustom;
			color: $white;
			position: relative;
			display: inline-block;
			padding: px2rem(6) 0;

			&:before, &:after {
				width: 0;
				position: absolute;
				height: 2px;
				background-color: $primary;
				display: block;
				content: "";
				bottom: 0;
				@include transitionCustom;
				opacity: 0;
			}
			&:before {
				right: 50%;
			}
			&:after {
				left: 50%;
			}
		}

		// Dropdown
		&.has-subMenu > a {
			display: flex;
			align-items: center;
			i {
				margin-left: px2rem(8);
				font-size: px2rem(10);
				flex-shrink: 0;
			}
		}

		// Active
		&.active > a, #{$no-touch} & > a:hover {
			&:before, &:after {
				width: 50%;
				opacity: 1;
			}
		}
		&.active > a {
			font-weight: $font-weight-bold;
		}

		// Sub menu
		> ul {
			background-color: $white;
			border-bottom: 2px solid $primary;
			a {
				display: block;
				padding: px2rem(10) px2rem(16);
				color: $body-color;
				@include underline(0,0);
				&:hover {
					color: $primary;
					background-color: $gray-100;
				}
			}
			li + li {
				border-top: 1px solid $border-color;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		display: flex !important;

		// Sub menu
		> li > ul {
			position: absolute;
			right: 0;
			top: 100%;
			min-width: 220px;
			max-width: 100%;
			box-shadow: $box-shadow-lg;
			transform: translateY(8px);
		}
	}
	@include media-breakpoint-down(md) {
		display: none;
		box-shadow: $box-shadow-lg;
		&.show, &.collapsing {
			display: block;
		}
		position: absolute;
		top: 100%;
		right: 0;
		background-color: darken($secondary, 5%);
		padding: spacer(2) 0;
		width: 100%;
		max-width: 300px;
		> li {
			margin: 0 !important;

			> a {
				@include font(px2rem(16), 1.5);
				@include fontFamily("base");
				letter-spacing: 0;
				text-transform: none;
				display: block;
				padding: spacer(2) spacer(3);
				color: $white;
			}
		}
	}
}


// **
// Footer
// **
.footer-container {
	color: $white;
	a {
		@include transitionCustom;
	}

	// Menu
	.footer-menu-list {
		@include font($font-size-sm, $line-height-sm);
		li + li {
			margin-top: spacer(2);
		}
		a {
			color: $gray-600;
			&:hover {
				color: $white;
			}
		}
	}

	// Menu partners
	.footer-menu-partners-list {
		li + li {
			margin-top: spacer(3);
		}
		a {
			@include underline(0,0);
		}
	}

	// Menu social
	.footer-menu-social-list {
		@include font(px2rem(36), 1);
		li {
			margin-bottom: spacer(3);
			margin-right: spacer(3);
		}
		a {
			@include underline(0,0);
			&:hover {
				color: $white;
			}
		}
	}

	// Title
	.title-collapse {
		display: flex;
		justify-content: space-between;
		align-items: center;
		i {
			flex-shrink: 0;
			margin-left: spacer(2);
			@include font(px2rem(12), 1);
			color: $primary;
		}
	}

	// Footer
	.footer {
		@include font($font-size-sm, $line-height-sm);
		justify-content: space-between;
		align-items: center;
		color: $gray-600;
		a {
			color: currentColor;
			&:hover {
				color: $white;
			}
		}

		.be {
			a {
				@include underline(0, 0);
			}
		}
	}

	// ** Responsive **
	@include media-breakpoint-down(md) {
		.footer-top-row {
			> div {
				order: 2;
			}
			.logo {
				order: 1;
			}
		}
	}
}
