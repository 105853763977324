/**
 * Contact data
 */
.component__contact-data {
	display: flex;
	align-items: center;
	color: currentColor;

	> i {
		align-self: flex-start;
		flex-shrink: 0;
		margin-right: spacer(3);
		@include font(px2rem(50), 1);
		color: $primary;
	}

	> div {
		flex-grow: 1;

		a {
			color: currentColor;
			@include font($font-size-xl, 1.3, $font-weight-bold);
			@include transitionCustom;
			@include underline(0, 1);
		}
	}

	ul {
		li {
			display: flex;
			align-items: center;

			+ li {
				margin-top: spacer(2);
			}

			> i {
				align-self: start;
				flex-shrink: 0;
				margin-right: spacer(3);
				border: 2px solid $secondary;
				width: 46px;
				height: 46px;
				display: flex;
				align-items: center;
				justify-content: center;
				@include font(px2rem(18), 1);
				background: $body-bg;
			}

			> div {
				flex-grow: 1;

				table {
					th + td, td + td {
						padding-left: spacer(2);
					}
				}
			}
		}
	}
}


/**
 * Navigation list
 */
.component__navigation-list {
	.item {
		display: flex;
		align-items: center;

		i {
			align-self: flex-start;
			color: $primary;
			@include font(px2rem(36), 1);
			flex-shrink: 0;
			margin-right: spacer(3);
		}

		> p {
			span, a {
				display: block;
			}

			a {
				@include transitionCustom;
				@include font($font-size-sm, $line-height-sm);
			}
		}
	}
}


/**
 * Image gallery / list
 */
.component__gallery {
	.image-zoom {
		img, &:before {
			border-radius: $border-radius;
		}
	}
}

/**
 * Branch (list)
 */
.component__branch {
	display: block;
	position: relative;

	img {
		border-radius: $border-radius;
	}

	&:before {
		left: 0;
		bottom: 0;
		right: 0;
		top: 0;
		content: "";
		display: block;
		background: rgba($black, .3);
		background: linear-gradient(45deg, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
		pointer-events: none;
		position: absolute;
		border-radius: $border-radius;
	}

	// Content
	.text {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		color: $white;
	}
}

/**
 * Branch (list) - homepage
 */
.component__branch-homepage {
	display: block;
	background: $white;
	border-radius: $border-radius;
	color: $body-color;
	position: relative;

	// Toggle
	[data-toggle="collapse"] {
		cursor: pointer;

		i {
			@include transitionCustom;
		}

		&[aria-expanded="true"] i {
			transform: scaleY(-1);
		}
	}

	// Collapsed content
	.content {
		z-index: 10;

		.check-list {
			font-size: px2rem(14);

			li:before {
				color: $body-color;
			}

			li + li {
				margin-top: spacer(3);
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.content {
			border-bottom-width: 0;
		}
	}
	@include media-breakpoint-up(md) {
		.content {
			border-bottom: 1px solid $gray-300;
			position: absolute !important;
			top: 100%;
			left: 0;
			right: 0;
			transform: translateY(-$border-radius);
			background-color: $white;
			border-radius: 0 0 $border-radius $border-radius;
			box-shadow: 0 6px 12px -6px rgba(0, 0, 0, 0.2);
		}
	}
}

/**
 * Current occupancy
 */
.component__current-occupancy {
	> p {
		padding-left: px2rem(20);
	}

	.status {
		@include font($font-size-sm, $line-height-sm, $font-weight-bold);
		position: relative;

		&:before {
			pointer-events: none;
			width: 6px;
			height: 6px;
			position: absolute;
			content: "";
			display: block;
			background-color: currentColor;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			border-radius: 100%;
		}
	}

	.note {
		@include font($font-size-xs, $line-height-xs, $font-weight-base);
	}
}


/**
 * Service list
 */
.component__service-list {
	.item:nth-child(1) a {
		border-top: 1px solid $gray-300;
	}

	a {
		height: 100%;
		display: flex;
		align-items: center;
		padding: spacer(3) spacer(4);
		@include underline(0, 0);
		border-bottom: 1px solid $gray-300;
		color: $body-color;
		@include transitionCustom;

		.image {
			margin-right: spacer(4);
			flex-shrink: 0;
			width: 54px;
		}

		.name {
			@include font(px2rem(24), 1.2);
			flex-grow: 1;
			@include transitionCustom;
		}

		i {
			flex-shrink: 0;
			@include transitionCustom;
			color: $gray-500;
		}

		&:hover {
			border-color: $primary;

			.name {
				color: $primary;
			}

			i {
				color: $primary;
				transform: translateX(3px);
			}
		}
	}

	@include media-breakpoint-up(md) {
		.item:nth-child(2) a {
			border-top: 1px solid $gray-300;
		}
	}
	@include media-breakpoint-down(sm) {
		a {
			.image {
				width: 46px;
			}

			.name {
				font-size: px2rem(20);
			}
		}
	}
	@include media-breakpoint-down(xs) {
		a {
			.image {
				width: 38px;
			}

			.name {
				font-size: px2rem(18);
			}
		}
	}
}


/**
 * Service list - grid
 */
.component__service-list-grid {
	a {
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		@include underline(0, 0);
		border: 2px solid $border-color;
		border-radius: $border-radius;
		color: $body-color;
		text-align: center;
		@include transitionCustom;

		.image {
			flex-shrink: 0;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.name {
			@include transitionCustom;
		}

		#{$no-touch} &:hover {
			border-color: $primary;
			box-shadow: $box-shadow;

			.name {
				color: $primary;
			}
		}
	}

	// No touch device
	@include media-breakpoint-up(md) {
		#{$no-touch} & a {
			position: relative;
			overflow: hidden;

			.text {
				position: absolute;
				margin: 0 !important;
				left: 0;
				top: 0;
				right: 0;
				display: block;
				padding: spacer(3) spacer(4);
				background-color: $white;
				transform: translateY(-100%);
				opacity: 0;
				@include transitionCustom;
			}

			&:hover .text {
				transform: none;
				opacity: 1;
				box-shadow: $box-shadow;
			}
		}
	}
}


/**
 * Service list (menu)
 */
.component__service-list-menu {
	li:nth-child(1) a {
		border-top: 1px solid $border-color;
	}

	a {
		height: 100%;
		display: flex;
		align-items: center;
		padding: spacer(3) spacer(3);
		@include underline(0, 0);
		border-bottom: 1px solid $border-color;
		color: $body-color;
		min-height: 64px;
		@include transitionCustom;

		.name {
			flex-grow: 1;
			@include transitionCustom;
		}

		i {
			flex-shrink: 0;
			@include transitionCustom;
			color: $gray-500;
			font-size: smaller;
		}

		&:hover {
			border-color: $primary;

			.name {
				color: $primary;
			}

			i {
				color: $primary;
				transform: translateX(3px);
			}
		}

		&.active {
			border-bottom-width: 2px;
			border-color: $primary;

			.name {
				font-weight: $font-weight-bold;
			}

			i {
				color: $body-color;
			}
		}
	}
}

/**
 * Reason list
 */
.component__reason-list {
	color: $body-color;

	.reason-container {
		height: 100%;
		background-color: $white;
		border-radius: $border-radius;
	}

	.title-container {
		position: relative;
		@include fontFamily("extra");
		font-style: italic;

		.n {
			@include font(px2rem(150), .5);
			color: $gray-100;
		}

		.title {
			@include fontFamily("extra");
			position: absolute;
			left: 0;
			bottom: -10%;
		}
	}

	.text {
		line-height: 1.8;
	}

	.text-container {
		text-align: left;
		.check-list li+li {
			margin-top: px2rem(8);
		}
	}
}

/**
 * Stepper
 */
.component__stepper {
	p {
		margin-left: spacer(4);
	}

	.n {
		@include fontFamily("extra");
		@include font(px2rem(48), 1);
		font-style: italic;
		position: relative;
		color: $primary;

		&:before {
			border-radius: 100%;
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(-50%, -40%);
			display: block;
			background-color: $primary-alt;
			width: 50px;
			height: 50px;
			pointer-events: none;
			z-index: -1;
		}
	}

	.text {
		margin-top: px2rem(12);
	}

	@include media-breakpoint-down(md) {
		.text br {
			display: none;
		}
	}
}

/**
 * Order card
 */
.component__order-card {
	background-color: $white;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12);
	border-radius: $border-radius;

	// Header
	.order-card-header {
		padding: $order-card-padding-y $order-card-padding-x 0;
		display: flex;
		align-items: center;

		> span {
			width: $order-card-header-n-size;
			height: $order-card-header-n-size;
			flex-shrink: 0;
			background-color: $primary-alt;
			border-radius: 100%;
			color: $primary;
			@include fontFamily("extra");
			@include font(px2rem(24), 1);
			font-style: italic;
			padding-bottom: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: $order-card-header-n-margin;
		}

		.title {
			@include font($font-size-lg, $line-height-lg);
			color: currentColor;
		}
	}

	// Footer
	.order-card-footer {
		padding: 0 $order-card-padding-x $order-card-padding-y;
	}

	// Content
	.order-card-content {
		padding: $order-card-padding-y $order-card-padding-x ($order-card-padding-y * 1.5);
	}

	// Theme: danger
	&.is-danger {
		background-color: $danger;
		color: $white;
	}

	@include media-breakpoint-up(xl) {
		.order-card-content {
			margin-left: ($order-card-header-n-size + $order-card-header-n-margin);
		}
	}
}

/**
 * Base table
 */
.component__table {
	tbody {
		> tr {
			td, th {
				height: 72px;
			}
		}
	}
}

/**
 * Delivery method table
 */
.component__table-delivery {
	tbody {
		label.name {
			margin: 0;
			display: block;
		}

		tr.has-subRow {
			td, th {
				border-bottom-width: 0 !important;
			}

			+ tr {
				td, th {
					height: auto;
					border-top-width: 0 !important;
					padding-top: 0;
				}
			}
		}
	}
}

/**
 * File list
 */
.component__file-list {
	border-top: 1px solid $border-color;

	a.name {
		height: 100%;
		display: flex;
		align-items: center;
		padding: spacer(2) 0;
		@include underline(0, 0);
		border-bottom: 1px solid $border-color;
		color: $body-color;
		min-height: 42px;
		@include transitionCustom;
		@include font($font-size-sm, 1.3);

		> span {
			flex-grow: 1;
			@include transitionCustom;
			word-break: break-all;
		}

		> i {
			flex-shrink: 0;
			@include transitionCustom;
			color: $gray-500;
			font-size: smaller;
			margin-left: spacer(2);
		}

		&:hover {
			border-color: $primary;

			.name {
				color: $primary;
			}

			i {
				color: $primary;
				transform: translateX(3px);
			}
		}

		&.active {
			border-bottom-width: 2px;
			border-color: $primary;

			.name {
				font-weight: $font-weight-bold;
			}

			i {
				color: $body-color;
			}
		}
	}
}

/**
 * Calendar slider
 */
.component__calendar-slider {
	.head {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.custom-select {
			flex-grow: 1;
			max-width: 274px;
			margin: 0 px2rem(12);
		}
	}
}

/**
 * Pictures slider
 */
.component__pictures-slider {
	position: relative;

	.text-container {
		background-color: $primary;
		color: $white;
		padding: px2rem(56) px2rem(44);
		position: relative;
		z-index: 10;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		> i {
			@include font(px2rem(28), 1);
			display: block;
		}

		.text {
			font-size: px2rem(32);
		}
	}

	@include media-breakpoint-up(xl) {
		.text-container {
			min-height: 490px;
		}
	}

	@include media-breakpoint-up(lg) {
		.text-container {
			min-height: 420px;
			border: 18px solid $white;
		}
		.slider {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	@include media-breakpoint-down(lg) {
		.text-container .text {
			font-size: px2rem(24);
		}
	}
	@include media-breakpoint-down(md) {
		.text-container {
			padding: px2rem(40) px2rem(32);

			> i {
				font-size: px2rem(20);
			}
		}
	}
}

/**
 * Paper size grid
 */
.component__paperSizeGrid {
	.image-container {
		margin-bottom: spacer(5);

		.image {
			position: relative;
			display: inline-block;
		}

		.label {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			@include font($font-size-sm, 1);
		}

		img {
			box-shadow: 16px 16px 20px rgba(0, 0, 0, 0.05);
		}

		.sticker {
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 50%;
			min-width: 60px;
			height: 60px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			text-transform: uppercase;
			@include font($font-size-sm, $line-height-sm);
			transform: translate(-50%, -50%);
		}
	}

	.price {
		margin-bottom: spacer(2);

		.value {
			font-weight: $font-weight-medium;
			font-size: 24px;
		}
	}

	.sale {
		display: inline-block;
		opacity: .4;
		margin-bottom: spacer(1);
		text-decoration: line-through;
		font-size: $font-size-sm;

		.value {
			font-size: 16px;
		}
	}
}


.component__editor-template {
	position: relative;
	.img-template {
		position: relative;
		z-index: 100;
		pointer-events: none;
	}

	&.is-picture {
		border: 8px solid currentColor;
		border-radius: 4px;
		> .component__cropper {
			left: 0;
			top: 0;
			right: 0;
		}
	}

	@include media-breakpoint-down(xs) {
		&.is-picture {
			border-width: 4px;
			border-radius: 2px;
		}
	}
}

/**
 * Cropper
 */
.component__cropper {
	$controlsIndent: px2rem(10);
	position: absolute;
	> .controls {
		right: $controlsIndent;
		bottom: $controlsIndent;
		position: absolute;
		display: flex;
		align-items: center;
		> i {
			margin-right: spacer(2);
			background: $white;
			border-radius: 50%;
			box-shadow: 0 2px 4px rgba($black, .2);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 22px;
			height: 22px;
			padding: 0 0 1px 1px;
		}
	}

	&[data-controls="left-top"] > .controls {
		left: $controlsIndent;
		top: $controlsIndent;
		right: auto;
		bottom: auto;
	}

	&[data-controls="right-top"] > .controls {
		top: $controlsIndent;
		bottom: auto;
	}

	&[data-controls="left-bottom"] > .controls {
		left: $controlsIndent;
		right: auto;
	}

	.dropzone {
		background-color: $gray-300;
		position: relative;
		z-index: 50;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: $gray-600;
		text-align: center;
		padding: 10%;
		* {
			pointer-events: none;
		}

		@include font(px2rem(13), 1.5);
		@include transitionCustom;

		&:before {
			left: 12px;
			right: 12px;
			bottom: 12px;
			top: 12px;
			border-radius: 8px;
			border: 3px dashed $gray-400;
			position: absolute;
			display: block;
			content: "";
			@include transitionCustom;
			pointer-events: none;
		}

		&:hover {
			cursor: pointer;
			&:before {
				border-color: $gray-900;
			}
		}

		&.is-active {
			background-color: $primary-alt;
			color: $primary;
			&:before {
				border-color: $primary;
			}
		}
	}
}


/**
 * Editor images horizontal container
 */
.component__editorImages-horizontalContainer {
	position: sticky;
	bottom: 0;
	z-index: 200;
	border-radius: 0;
}


/**
 * Editor images
 */
.component__editorImages {
	background-color: $gray-100;
	border-radius: $border-radius;
	display: flex;
	flex-direction: column;
	position: relative;

	.head .upload label {
		margin: 0;
	}

	@mixin vertical {
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;

		.list-container {
			overflow-y: auto;
		}
		.list {
			margin-left: -(px2rem(6));
			margin-right: -(px2rem(6));
			.component__editorImage {
				margin-left: px2rem(6);
				margin-right: px2rem(6);
			}
		}

		.component__editorImage + .component__editorImage {
			margin-top: spacer(2);
		}
	}

	@mixin horizontal {
		.list-container {
			overflow-x: auto;
			overflow-y: hidden;
			min-height: px2rem(90 + 2*6);
		}
		.list {
			//margin-top: -(px2rem(6));
			//margin-bottom: -(px2rem(6));
			width: max-content;

			.component__editorImage {
				//margin-top: px2rem(6);
				//margin-bottom: px2rem(6);
				width: px2rem(160);
				height: px2rem(90);
				min-height: 0;
				padding: 0;
				display: inline-block;
			}
		}

		.component__editorImage + .component__editorImage {
			margin-left: px2rem(4);
		}
	}

	.list-container {
		flex-grow: 1;
		flex-basis: 0;
		position: relative;
		margin-right: -(px2rem(6));
		padding-right: px2rem(6);
		&.os-host-overflow-y {
			margin-right: -(px2rem(8));
			padding-right: px2rem(12);
		}
		&.os-host-overflow-x {
			margin-bottom: px2rem(8);
			padding-bottom: px2rem(8);
		}

		.list {
			position: relative;
		}
	}

	// dropzone
	.dropzone {
		display: none;
	}
	&.is-active {
		* {
			pointer-events: none;
		}
		.dropzone {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			border-radius: inherit;
			z-index: 50;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 10%;
			background-color: $primary-alt;
			color: $primary;
			pointer-events: none;

			@include font(px2rem(13), 1.5);
			@include transitionCustom;

			&:before {
				left: 12px;
				right: 12px;
				bottom: 12px;
				top: 12px;
				border-radius: 8px;
				border: 3px dashed $primary;
				position: absolute;
				display: block;
				content: "";
				@include transitionCustom;
				pointer-events: none;
			}
		}
	}

	// Vertical
	&.is-vertical {
		@include media-breakpoint-up(md) {
			@include vertical;
		}
		@include media-breakpoint-down(sm) {
			@include horizontal;
		}
	}

	// Vertical
	&.is-horizontal {
		@include horizontal;
		@include media-breakpoint-up(md) {
			.head {
				display: flex;
				align-items: center;
				justify-content: space-between;
				p + p {
					margin-left: spacer(2);
				}
				.upload {
					max-width: 300px;
					flex-grow: 1;
					flex-shrink: 0;
				}
				.note {
					margin-top: 0 !important;
					text-align: right;
				}
			}
		}
	}
}


/**
 * Editor image
 */
.component__editorImage {
	background-color: darken($gray-100, 6%);
	background-size: cover;
	background-position: center center;
	position: relative;
	min-height: 100px;
	border-radius: $border-radius;
	overflow: hidden;
	padding-bottom: 56.25%;
	cursor: move;

	> .btn {
		position: absolute;
		right: px2rem(8);
		bottom: px2rem(8);
	}
}


/**
 * Fullscreen loading component
 */
.component__loading {
	display: flex;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
	align-items: center;
	justify-content: center;
	color: $white;
	z-index: 1000;
	opacity: 0;
	@include transitionCustom(opacity);
	&.show {
		opacity: 1;
	}

	&:not(.show) {
		pointer-events: none;
	}
}
