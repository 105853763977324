@import "../../../../../node_modules/slick-slider/slick/slick.scss";

// Hide slide before init except first one
.slick-preInit {
	&:not(.slick-initialized) {
		.slide:not(:first-child) {
			display: none;
		}
	}
}

// Arrows
.slick-arrow {
	position: absolute;
	z-index: 20;
	top: 50%;
	color: $white;
	@include font(px2rem(18), 1);
	display: flex;
	justify-content: center;
	align-items: center;
	background: $black;
	width: 42px;
	height: 42px;
	border-width: 0;
	padding: 0;
	margin: 0;
	outline: 0 !important;
	box-shadow: none;
	border-radius: 100%;

	&:hover {
		background-color: $primary;
	}

	&.prev {
		left: 0;
		transform: translate(-50%, -50%);
	}

	&.next {
		right: 0;
		transform: translate(50%, -50%);
	}
}
