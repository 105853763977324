$_font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: 'Inter', #{$_font};
$font-family-title: 'Inter', #{$_font};
$font-family-extra: 'Playfair Display', 'Inter', #{$_font};
$fontDefault: $font-family-sans-serif;

$fontList: ('title': $font-family-title, 'extra':  $font-family-extra, 'base': $font-family-sans-serif, 'ico': 'icomoon');
$dir: "..";


$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1240px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);


// **
// Colors
// **
$white: #fff;
$body-color: #263137;
$body-bg: $white;
$gray-100: #F4F4F4;
$gray-200: #E4E4E4;
$gray-300: #DEDEDE;
$gray-400: #C5C5C5;//
$gray-500: #A7A9B2;
$gray-600:#7C7E85;
$gray-800: #344148;
$gray-900: #263036;
$dark: $gray-900;
$primary: #DA2129;
$info: #328FFE;
$primary-alt: #FFDEE0;
$secondary: #272727;//
$success: #36C856;//
$warning: #FF7A00;//
$danger: $primary;
$light: $gray-200;//

$theme-colors: (
	"gray": $gray-500,
	"gray-dark": $gray-600,
	white: $white
);

$spacer: 1rem;
$spacers: (
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 2),
	6: ($spacer * 2.5),
	7: ($spacer * 3),
	8: ($spacer * 3.5),
	9: ($spacer * 4),
	10: ($spacer * 5),
	11: ($spacer * 6),
	12: ($spacer * 7),
	13: ($spacer * 8),
	14: ($spacer * 9),
	15: ($spacer * 10),
	16: ($spacer * 12),
	17: ($spacer * 14),
	18: ($spacer * 16),
	19: ($spacer * 18),
	20: ($spacer * 20),
);

$border-radius: 4px;
$border-radius-lg: 4px;

// **
// Font size
// **
$font-size-base: px2rem(15);
$line-height-base: 1.4;
$font-size-xl: px2rem(20);
$line-height-xl: 1.5;
$font-size-lg: px2rem(17);
$line-height-lg: 1.6;
$font-size-sm: px2rem(13);
$line-height-sm: 1.5;
$font-size-xs: px2rem(12);
$line-height-xs: 1.5;
$font-size-xxs: px2rem(11);
$line-height-xxs: 1.4;

// Font - weight
$font-weight-xbold: 700 !default;
$font-weight-bold: 700 !default;
$font-weight-semibold: 600 !default;
$font-weight-medium: 500 !default;
$font-weight-normal: 400 !default;
$font-weight-light: 400 !default;
$font-weight-xlight: 400 !default;

// Headline
$headings-font-family: $font-family-title;
$headings-margin-bottom: 0;
$headings-line-height: 1.3;
$headings-font-weight: $font-weight-normal;
$h1-font-size: px2rem(56);
$h2-font-size: px2rem(36);
$h3-font-size: px2rem(24);
$h4-font-size: px2rem(20);
$h5-font-size: px2rem(16);
/*
$h5-font-size: px2rem(16);
$h6-font-size: px2rem(12);*/

// Tabs
$nav-tabs-border-width: 0;
$nav-bg: $gray-400;
$nav-tabs-link-bg: $gray-300;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-hover-bg: $gray-100;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-color: $body-color;

// Breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: 0;
$breadcrumb-item-padding: 0;
$breadcrumb-font-size: $font-size-base;
$breadcrumb-margin-bottom: 0;

// **
// Others
// **
$letter-spacing: 0;
$header-height: 110px;
$header-height-xs: 80px;
$transition-default: .4s;
$box-shadow: 0 0 24px rgba(#000, .05);
$box-shadow-sm: 0 1px 2px 0 rgba(185, 185, 185, 0.5);
;

// **
// Input
// **
$input-height: px2rem(46);
$input-padding-x: px2rem(14);
$input-bg: $gray-100;
//$input-height-sm: px2rem(30);
//$input-padding-x-sm: 0;
$input-border-color: $gray-100;
$input-box-shadow: none;
$input-font-size: $font-size-sm;
$input-font-size-sm: $font-size-sm;
$label-line-height: 1.2;
$label-font-size: $font-size-sm;

// **
// Button
// **
$btn-font-size: px2rem(14);
$btn-padding-x: px2rem(23);
$btn-padding-y: px2rem(11);
$btn-line-height: px2rem(22);
$btn-border-radius: px2rem(23);

$btn-font-size-lg: $font-size-base;
$btn-line-height-lg: px2rem(22);
$btn-padding-x-lg: px2rem(34);
$btn-padding-y-lg: px2rem(18);
$btn-border-radius-lg: 30px;

$btn-font-size-sm: $font-size-sm;
$btn-line-height-sm: px2rem(18);
$btn-padding-x-sm: px2rem(18);
$btn-padding-y-sm: px2rem(7);
$btn-border-radius-sm: px2rem(17);

$btn-box-shadow: 0 12px 16px 0 rgba(218,33,41,0.05);
$btn-font-weight: $font-weight-medium;
$btn-border-width: 1px;
$btn-min-height: $btn-line-height + 2 * $btn-padding-y + 2 * px2rem($btn-border-width);
$btn-min-height-lg: $btn-line-height-lg + 2 * $btn-padding-y-lg + 2 * px2rem($btn-border-width);
$btn-min-height-sm: $btn-line-height-sm + 2 * $btn-padding-y-sm + 2 * px2rem($btn-border-width);


// **
// Font styles
// **
$font-size-styles: (
	"base": $font-size-base $line-height-base,
	//"md": $font-size-md $line-height-md,
	"sm": $font-size-sm $line-height-sm,
	"xs": $font-size-xs $line-height-xs,
	"xxs": $font-size-xxs $line-height-xxs,
	"lg": $font-size-lg $line-height-lg,
	"xl": $font-size-xl $line-height-xl
);


// **
// Font weights
// **
$font-weight-styles: (
	"bold": $font-weight-bold,
	"medium": $font-weight-medium,
	"base": $font-weight-normal,
	"light": $font-weight-light,
);

// Gutters
$grid-gutter-width: 20px;
$grid-gutter-spacer: $grid-gutter-width / 2;
$grid-gutters: (
	md: 20px,
	sm: 16px,
	xs: 10px
);

// Badges
$badge-font-size: px2rem(13);
$badge-padding-x: px2rem(8);
$badge-padding-y: px2rem(6);

// Pagination
$pagination-bg: transparent;
$pagination-border-width: 2px;
$pagination-border-color: transparent;
$pagination-disabled-bg: transparent;
$pagination-disabled-border-color: transparent;
$pagination-disabled-color: $body-color;
$pagination-active-bg: transparent;
$pagination-active-border-color: $primary;
$pagination-active-color: $body-color;
$pagination-hover-bg: transparent;
$pagination-hover-color: $body-color;
$pagination-hover-border-color: $secondary;
$pagination-color: $body-color;
$pagination-padding-x: px2rem(2);
$pagination-padding-y: px2rem(0);
$pagination-min-width: 30px;

// Custom
$custom-control-indicator-bg: $gray-100;
$custom-control-indicator-border-color: $gray-400;
$custom-control-indicator-border-width: 1px;
//$custom-control-indicator-active-bg: $body-bg;
//$custom-control-indicator-active-border-color: $secondary;
//$custom-control-indicator-active-color: $secondary;
$custom-control-indicator-checked-bg: transparent;
$custom-control-indicator-checked-border-color: $primary;
$custom-control-indicator-checked-border-width: 6px;
$custom-control-indicator-checked-color: $body-color;
$custom-control-indicator-disabled-bg: $gray-300;
$custom-control-indicator-size: px2rem(22);
$custom-select-box-shadow: none;

// Modal
$modal-header-border-width: 0;
$modal-lg: 838px;
$modal-md: 660px;
$modal-header-padding: px2rem(30) px2rem(30) 0;
$modal-inner-padding: px2rem(30);
$modal-footer-border-width: 1px;


// Popover
$popover-body-padding-x: px2rem(24);
$popover-body-padding-y: px2rem(20);
$popover-font-size: px2rem(14);
$popover-line-height: 1.5;
$popover-border-width: 1px;
$popover-border-color: $gray-300;
$popover-box-shadow: 0 12px 64px 0 rgba(0,0,0,0.13);
$popover-max-width: 320px;

// Table
$table-accent-bg: $gray-100;
$table-striped-order: even;
$table-cell-padding: px2rem(12) px2rem(18);

// Order card
$order-card-padding-x: px2rem(20);
$order-card-padding-y: px2rem(20);
$order-card-header-n-size: px2rem(50);
$order-card-header-n-margin: px2rem(16);

// Dropdown

$enable-shadows: true;
