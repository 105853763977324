// **
// Breadcrumbs
// **
.breadcrumb {
	$indent: px2rem(26);
	color: $secondary;

	li {
		position: relative;
		padding-right: $indent;
		margin-top: .125rem;
		margin-bottom: .125rem;

		a {
			@include underline(0,0);
			@include transitionCustom(color);
			color: $gray-600;

			&:hover {
				color: $primary;
			}
		}

		// Divider
		&:before {
			display: none !important;
		}

		&:not(:last-child) {
			&:after {
				pointer-events: none;
				content: $icon-arrow-sm-right;
				position: absolute;
				@include fontFamily("ico");
				top: 50%;
				right: ($indent / 2);
				margin-top: -1px;
				display: block;
				transform: translate(50%, -50%);
				@include font(px2rem(4), 1);
				color: $primary;
			}
		}
	}
}


/**
 * Popover
 */
.popover {
	.popover-body {
		line-height: $popover-line-height;
	}
}


/*
// **
// Pagination
// **
.pagination {
	justify-content: center;
	@include fontFamily("title");
	@include font(px2rem(16));

	.page-link, span {
		@include transitionCustom;
		display: block;
		margin: px2rem(2);
		min-width: $pagination-min-width;
		line-height: ($pagination-min-width - ($pagination-border-width * 2));
		text-align: center;
		padding-top: 1px;
	}
}

// **
// Checkbox
// **
.custom-control {
	&.custom-checkbox {
		.custom-control-label {
			display: flex;
			align-items: center;

			i {
				@include font(px2rem(15), 1, $font-weight-semibold);
				margin-left: spacer(2);
			}
		}

		&:hover {
			.custom-control-label {
				color: $warning;

				&:before {
					box-shadow: 0 2px 14px rgba($warning, .15);
					border-color: $warning;
					background-color: rgba($warning, .2);
				}
			}
		}
	}

}
*/

/**
 * Modal
 */
.modal {
	.modal-header {
		display: block;

		.close {
			position: absolute;
			right: spacer(3);
			top: spacer(3);
			@include font(px2rem(10), 1);
			color: $primary;
			opacity: 1;
			padding: 0;
			margin: 0;

			&:hover {
				color: $body-color;
			}
		}
	}
}


// **
// Tabs
// **
.tabs-container {
	overflow: hidden;
	.nav-tabs {
		background: $nav-tabs-link-bg;
		align-items: flex-end;
		.nav-link {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-weight: $font-weight-semibold;
			color: $nav-tabs-link-color;
			@include transitionCustom;
			svg {
				height: 30px;
				width: auto;
			}
			&:hover {
				background-color: $nav-tabs-link-hover-bg;
				 color: $body-color;
			}
		}
	}
}

/**
 * Table
 */
.table {
	&.table-striped:not(.table-bordered) {
		th, td {
			border-top-width: 0;
		}
	}
	&.table-bordered {
		thead th {
			background-color: $gray-100;
			border-color: $gray-100 !important;
			@include font($font-size-sm, $line-height-sm, $font-weight-normal);
		}
		td, th {
			border-left-width: 0;
			border-right-width: 0;
			vertical-align: middle;
		}
		tfoot tr:last-child {
			td {
				border-bottom-width: 0;
			}
		}
	}
}
